@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto+Mono:wght@400;500;600;700&family=Merriweather:wght@600;700&display=swap');

body {
	margin: 0;
	background-color: #141313;
	color: #FFFFFF;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
}

pre {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

a {
	color: #84bef0;
}

* {
	-webkit-user-select: initial;
	-moz-user-select: initial;
	-ms-user-select: initial;
	user-select: initial;
	scrollbar-width: thin;
}

*::-webkit-scrollbar {
	width: 8px;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(242, 242, 242, 0.1);
}