.leftColumn {
	height: 100%;
	width: 16rem;
	padding-right: 4rem;
	border: 0;
	vertical-align: top;
	text-align: left;
}

.mainContent {
	height: 100%;
	border: 0;
	vertical-align: top;
	text-align: left;
	font-weight: 400;
}

.tableContainer {
	width: 100%;
	max-width: 100rem;
	margin: 0 auto;
}

.allContainer {
	border: 0;
	overflow-y: scroll;
	padding: 6rem 3rem;
	width: calc(100vw - 6rem);
	height: calc(100vh - 12rem);
}

.mobileHeader {
	background-color: #141313;
	color: #cccccc;
	padding: 0.5rem 1rem;
}

.mobileHeaderIcon {
	height: 1rem;
	width: 1rem;
	margin-right: 1rem;
	border-radius: 0.25rem;
}

.mobileHeaderLeft {
	display: flex;
	align-items: center;
}

.mobileHeaderButtonFlex {
	display: flex;
	align-items: center;
}

.mobileContentOuter {
	width: 100%;
	display: flex;
	align-items: center;
}

.mobileContent {
	width: calc(100% - 5rem);
	height: calc(100% - 2.5rem);
	padding: 1.5rem 2.5rem 1rem 2.5rem;
}

.mobileNavMenuHolder {
	width: 16rem;
	max-width: calc(100vw - 6rem);
	height: 100%;
	padding: 3rem;
	background-color: #141313;
}