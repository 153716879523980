.navMenuOuter {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 12rem);
	position: fixed;
	overflow-y: auto;
	width: 16rem;
}

.siteTitle {
	font-size: 1.3rem;
	font-weight: 600;
}

.navButtonContainer {
	padding-top: 4rem;
}