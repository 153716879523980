.fullWidth {
	width: 100%;
}

.bottomButtons {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.rightFloat {
	text-align: right;
}

.storyPreview {
	width: 100%;
	height: 315px;
	overflow: auto;
}