.informationOuter {
	display: flex;
	align-items: center;
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.informationOuterArticle {
	display: flex;
	align-items: center;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.iconHolderHolder {
	width: 2.75rem;
	height: 2.75rem;
	display: block;
}

.iconHolder {
	width: 2.75rem;
	height: 2.75rem;
	position: relative;
}

.icon {
	display: block;
	width: 2.75rem;
	height: 2.75rem;
	position: absolute;
	background: rgb(35,17,72);background: rgb(35,17,72);
	background: -moz-linear-gradient(45deg, rgba(35,17,72,1) 0%, rgba(27,19,144,1) 40%, rgba(14,21,251,1) 100%);
	background: -webkit-linear-gradient(45deg, rgba(35,17,72,1) 0%, rgba(27,19,144,1) 40%, rgba(14,21,251,1) 100%);
	background: linear-gradient(45deg, rgba(35,17,72,1) 0%, rgba(27,19,144,1) 40%, rgba(14,21,251,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#231148",endColorstr="#0e15fb",GradientType=1);
	border-radius: 0.5rem;
}

.iconInfoHolder {
	position: absolute;
	z-index: 2;
	width: 2.75rem;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.iconInfo {
	font-family: 'Merriweather', serif;
	font-weight: 700;
	font-size: 1.4rem;
	margin-top: 0.2rem;
	color: #D6D6D6;
}

.infoTextHolder {
	margin-left: 1.5rem;
	font-family: 'Inter', sans-serif;
}

.informationTitle {
	display: block;
	color: #BFBFBF;
	font-weight: 700;
	margin-bottom: 0.75rem;
}

.informationContent {
	display: block;
	color: #BFBFBF;
	font-weight: 400;
}

.informationTitleArticle {
	display: block;
	color: #f2f2f2;
	font-weight: 700;
	margin-bottom: 0.75rem;
}

.informationContentArticle {
	display: block;
	color: #f2f2f2;
	font-weight: 400;
}